exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-admin-site-js": () => import("./../../../src/pages/admin-site.js" /* webpackChunkName: "component---src-pages-admin-site-js" */),
  "component---src-pages-appsinti-js": () => import("./../../../src/pages/appsinti.js" /* webpackChunkName: "component---src-pages-appsinti-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-digital-menu-js": () => import("./../../../src/pages/digital-menu.js" /* webpackChunkName: "component---src-pages-digital-menu-js" */),
  "component---src-pages-dotell-js": () => import("./../../../src/pages/dotell.js" /* webpackChunkName: "component---src-pages-dotell-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manager-site-js": () => import("./../../../src/pages/manager-site.js" /* webpackChunkName: "component---src-pages-manager-site-js" */),
  "component---src-pages-reservations-js": () => import("./../../../src/pages/reservations.js" /* webpackChunkName: "component---src-pages-reservations-js" */),
  "component---src-pages-survey-creator-js": () => import("./../../../src/pages/survey-creator.js" /* webpackChunkName: "component---src-pages-survey-creator-js" */),
  "component---src-pages-taskme-js": () => import("./../../../src/pages/taskme.js" /* webpackChunkName: "component---src-pages-taskme-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

